import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import react from 'react';
import withNeon from "react-neon";
import Tile from "../../components/Tile.js";
import effect from "../../effects/cloudsfs.js";
const CloudShader = withNeon(Tile, effect);
export const frontmatter = {
  title: 'Clouds',
  author: 'Chris Neale',
  draft: false,
  date: '2018-10-03T23:40:00.00Z',
  tags: ['fullscreen']
}
export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>


<MDXTag name="h3" components={components}>{`Fullscreen Shader`}</MDXTag>
<MDXTag name="p" components={components}>{`React Neon fullscreen effects by passing an option of 'fullscreen' that's set to true. This will attach the effect to the top left of the screen and set it's dimensions to match the page.`}</MDXTag>
<CloudShader mixmode="screen" />
<MDXTag name="p" components={components}>{`In the example code below an image Tile component has been used for the ResizeObserver to listen to, but the effect could be attached to a real component in a proper app or website.`}</MDXTag>
<MDXTag name="h3" components={components}>{`Example Code`}</MDXTag></MDXTag>

export const _frontmatter = {};

  